import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import styled from "styled-components";

import Section from "../../../components/Section";

interface StaticQueryProps {
  backgroundImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}

export function Section1() {
  const data: StaticQueryProps = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "main_background.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Section>
      <BackgroundImageContainer>
        <BackgroundImg fluid={data.backgroundImage.childImageSharp.fluid} />
      </BackgroundImageContainer>
      <ContentContainer>
        <Indicator>
          <IndicatorSubText>MENU</IndicatorSubText>
          <IndicatorMainText>전체 메뉴</IndicatorMainText>
        </Indicator>
      </ContentContainer>
    </Section>
  );
}

const BackgroundImageContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 177px;
  z-index: -1;
`;

const BackgroundImg = styled(props => <Img {...props} />)`
  height: inherit;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 177px;
  padding-top: 107px;
`;

const Indicator = styled.div`
  width: 184px;
  height: 110px;
  background-color: #c92a2a;
  margin: auto;
  padding: 24px;
`;

const IndicatorSubText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.41px;
  color: #ffffff;
  text-align: center;
`;

const IndicatorMainText = styled.p`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.68px;
  text-align: center;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
`;
