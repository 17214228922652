import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";
import styled from "styled-components";

import Section from "../../../components/Section";
import { FoodCard, FoodCardProps } from "../../../components/FoodCard/Mobile";

interface StaticQueryProps {
  porkSoupRiceImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  sausageSoupRiceImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  mixedSoupRiceImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  assortedSoupRiceImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  boildPorkSliceAndSoupImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  sausageImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  boildPorkSliceImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  assortedBoildPorkSliceImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}

export function Section2() {
  const imgData: StaticQueryProps = useStaticQuery(graphql`
    query {
      porkSoupRiceImage: file(relativePath: { eq: "pork_soup_rice.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sausageSoupRiceImage: file(relativePath: { eq: "sausage_soup_rice.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mixedSoupRiceImage: file(relativePath: { eq: "mixed_soup_rice.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      assortedSoupRiceImage: file(relativePath: { eq: "assorted_soup_rice.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      boildPorkSliceAndSoupImage: file(relativePath: { eq: "boild_pork_slice_and_soup.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sausageImage: file(relativePath: { eq: "sausage.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      boildPorkSliceImage: file(relativePath: { eq: "boild_pork_slice.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      assortedBoildPorkSliceImage: file(relativePath: { eq: "assorted_boild_pork_slice.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const soupData: FoodCardProps[] = [
    {
      image: imgData.porkSoupRiceImage.childImageSharp.fluid,
      name: "돼지국밥",
      description: ["최고급 생고기만 고집하여 부드럽게 삶아내어", "든든하게 먹을 수 있는 국밥"],
    },
    {
      image: imgData.sausageSoupRiceImage.childImageSharp.fluid,
      name: "순대국밥",
      description: ["속이 꽉 찬 수제순대와 담백하게 삶아낸", "고기가 들어가 진한 국물맛을 내는 알찬 국밥"],
    },
    {
      image: imgData.mixedSoupRiceImage.childImageSharp.fluid,
      name: "섞어국밥",
      description: ["고기와 내장을 함께 끓여내어 깔끔하면서도", "풍부한 맛을 내는 국밥", "(내장만 넣은 내장국밥 가능)"],
    },
    {
      image: imgData.assortedSoupRiceImage.childImageSharp.fluid,
      name: "모듬국밥",
      description: ["고기, 순대, 내장을 모두 맛볼수 있는", "든든하고 푸짐한 국밥"],
    },
  ];

  const foodData: FoodCardProps[] = [
    {
      image: imgData.boildPorkSliceAndSoupImage.childImageSharp.fluid,
      name: "수육백반",
      description: ["수육고기와 돼지국밥 국물을 동시에 맛볼수 있는", "깔끔하고 담백한 메뉴"],
    },
    {
      image: imgData.sausageImage.childImageSharp.fluid,
      name: "순대1인분",
      description: ["신선한 재료들로 직접 만든 수제순대와", "돼지국밥 국물을 함께 맛볼 수 있는 영양식!"],
    },
    {
      image: imgData.boildPorkSliceImage.childImageSharp.fluid,
      name: "수육",
      description: ["최고급 생고기를 사골국물에 삶아내어", "고소하고 부드러우며 잡내없이 삶아낸 건강음식"],
    },
    {
      image: imgData.assortedBoildPorkSliceImage.childImageSharp.fluid,
      name: "모듬수육",
      description: ["고기, 순대, 내장을 다양하게 맛볼 수 있는", "인기메뉴"],
    },
    {
      name: "순대버섯전골",
      description: ["칼칼한 국물에 다양한 버섯과 수육고기", "순대까지 알차게 즐길 수 있는 메뉴", "(본점에서만 판매)"],
    },
  ];

  return (
    <Section>
      <ContentContainer>
        <ContentWrapper>
          <div>
            {soupData.map(data => (
              <FoodCard key={data.name} image={data.image} name={data.name} description={data.description} />
            ))}
          </div>
          <Border />
          <div>
            {foodData.map(data => (
              <FoodCard key={data.name} image={data.image} name={data.name} description={data.description} />
            ))}
          </div>
        </ContentWrapper>
      </ContentContainer>
    </Section>
  );
}

const ContentContainer = styled.div`
  width: 100%;
  padding: 70px 0px;
`;

const ContentWrapper = styled.div`
  width: 312px;
  height: 100%;
  margin: 0 auto;
`;

const Border = styled.div`
  width: 312px;
  height: 1px;
  background-color: #dee2e6;
  margin: 0;
`;
