import React from "react";

import { useMediaQueryPlatform } from "../../components/Shared/MediaQuery";

import { MenuPage as Desktop } from "./desktop";
import { MenuPage as Mobile } from "./mobile";
import Layout from "../../components/Layout";

export default function Container() {
  const platform = useMediaQueryPlatform();

  switch (platform) {
    case "desktop":
      return (
        <Layout>
          <Desktop />
        </Layout>
      );
    case "mobile":
      return (
        <Layout>
          <Mobile />
        </Layout>
      );
  }
}
